import React, { Suspense, lazy } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import PreLoader from './components/web/include/PreLoader';
const Home = lazy(() => import('./components/web/Home'));
const About = lazy(() => import('./components/web/About'));
const ContactUs = lazy(() => import('./components/web/ContactUs'));
const Events = lazy(() => import('./components/web/Events'));
const EventDetails = lazy(() => import('./components/web/EventDetails'));
const CreateEventtwo = lazy(() => import('./components/web/CreateEventtwo'));
const DocumentForm = lazy(() => import('./components/web/DocumentForm'));
const EditEvent = lazy(() => import('./components/web/EditEvent'));
const MyEarning = lazy(() => import('./components/web/MyEarning'));
const MyEvents = lazy(() => import('./components/web/MyEvents'));
const SelectDateTime = lazy(() => import('./components/web/SelectDateTime'));
const TermsOfUse = lazy(() => import('./components/web/TermsOfUse'));
const PrivacyPolicy = lazy(() => import('./components/web/PrivacyPolicy'));
const MyProfile = lazy(() => import('./components/web/MyProfile'));
const AggrementPDF = lazy(() => import('./components/web/AggrementPDF'));
const EventGuests = lazy(() => import('./components/web/EventGuests'));
const GuestInfoList = lazy(() => import('./components/web/GuestInfoList'));
const GooglePlace = lazy(() => import('./components/web/GooglePlace'));
const Faq = lazy(() => import('./components/web/Faq'));
const CorporateGifts = lazy(() => import('./components/web/CorporateGifts'));
const Gifts = lazy(() => import('./components/web/Gifts'));
const Giftsdetails = lazy(() => import('./components/web/Giftsdetails'));
const Giftpayment = lazy(() => import('./components/web/Giftpayment'));
const CreateGift = lazy(() => import('./components/web/CreateGift'));
const MyGift = lazy(() => import('./components/web/MyGift'));
const EditGift = lazy(() => import('./components/web/EditGift'));
const AddReview = lazy(() => import('./components/web/AddReview'));
const ThankYou = lazy(() => import('./components/web/ThankYou'));
const Magicshow = lazy(() => import('./components/web/Magicshow'));

function App() {
  return (
    <Suspense fallback={<PreLoader />}>
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/about-us" exact component={About} />
        <Route path="/contact-us" exact component={ContactUs} />
        <Route path="/events/:category" exact component={Events} />
        <Route path="/event/:slug" exact component={EventDetails} />
        <Route path="/create-event" exact component={CreateEventtwo} />

        <Route path="/us-nonus-form" exact component={DocumentForm} />
        <Route path="/edit-event" exact component={EditEvent} />
        <Route path="/my-earning" exact component={MyEarning} />
        <Route path="/my-events" exact component={MyEvents} />
        <Route path="/select-date-time" exact component={SelectDateTime} />
        <Route path="/reset-password/:host_id" exact component={Home} />

        <Route path="/terms-of-use" exact component={TermsOfUse} />
        <Route path="/privacy-policy" exact component={PrivacyPolicy} />

        <Route path="/my-profile" exact component={MyProfile} />

        <Route path="/aggrement-pdf" exact component={AggrementPDF} />

        <Route path="/event-guests" exact component={EventGuests} />

        <Route path="/guest-info-add/:booking_id" exact component={Home} />
        <Route path="/guest-info-list/:booking_id" exact component={GuestInfoList} />

        <Route path="/googlePlace" exact component={GooglePlace} />

        <Route path="/faq" exact component={Faq} />

        <Route path="/corporate-gift" exact component={CorporateGifts} />

        <Route path="/gifts/:id" exact component={Gifts} />

        <Route path="/giftsdetails" exact component={Giftsdetails} />
        <Route path="/giftpayment" exact component={Giftpayment} />
        <Route path="/create-gift" exact component={CreateGift} />
        <Route path="/my-gifts" exact component={MyGift} />
        <Route path="/edit-gifts" exact component={EditGift} />

        <Route path="/add-review" exact component={AddReview} />
        <Route path="/thank-you" exact component={ThankYou} />
        <Route path="/magic-show" exact component={Magicshow} />

        <Redirect to="/" />
      </Switch>
    </Suspense>
  );
}

export default App;